<template>
    <div id="box">
        <Header :headerIndex="2"></Header>
        <div class="container">
            <div class="index">
                <div class="part1">
                    <div class="m1340">
                        <div class="title tc">MAP</div>
                        <div class="introduction tc">Map printing instructions. Map printing instructionsMap printing
                            instructions. Map printing instructions</div>
                        <div class="map_box">
                            <div class="map_top flex-box">
                                <div class="search_box flex-box mr20">
                                    <input type="text" class="flex-grow-1" placeholder="Membership Number" v-model="amount" @keyup.enter="getlist">
                                    <a href="javascript:;" @click="getlist"></a>
                                </div>
                                <div class="flex-box flex-grow-1">
                                    <a href="javascript:;" class="btn btn1 fs18 colf">200</a>
                                    <a href="javascript:;" class="btn btn2 fs18 colf">500</a>
                                    <a href="javascript:;" class="btn btn3 fs18 colf">1000</a>
                                    <a href="javascript:;" class="btn btn4 fs18 colf">2000</a>
                                    <a href="javascript:;" class="btn btn5 fs18 colf">5000</a>
                                </div>
                            </div>
                            <div class="map_scroll">
                                <div class="map_bottom tc">
                                    <div class="map_line map_line1 flex-box flex-center">
                                        <div class="map_item">
                                            <template v-if="list.id">
                                                <div class="top tc" :class="'backcolor' + list.level" @click="getlist3(list.access_id)">
                                                    <div class="colf">【{{ list.id }}】</div>
                                                    <div class="colf opa">{{ list.idnum }}</div>
                                                </div>
                                                <div class="flex-box bottom" @click="getlist3(list.access_id)">
                                                    <div class="flex-grow-2">总</div>
                                                    <div class="flex-grow-5">{{ Number(list.left) + Number(list.right) }}</div>
                                                </div>
                                            </template>
                                            <div v-else class="fs12 col89 vacancy">VACANCY</div>

                                        </div>
                                    </div>
                                    <div class="line1"></div>
                                    <div class="map_line map_line2 flex-box flex-around">
                                        <div class="map_item">
                                            <template v-if="list.children && list.children[0]">
                                                <div class="top" :class="'backcolor' + list.children[0].level" @click="getlist2(list.children[0].id)">
                                                    <div class="colf">【{{ list.children[0].id }}】</div>
                                                    <div class="colf opa">{{ list.children[0].idnum }}</div>
                                                </div>
                                                <div class="flex-box bottom" @click="getlist2(list.children[0].id)">
                                                    <div class="flex-grow-2">总</div>
                                                    <div class="flex-grow-5">
                                                        {{ Number(list.children[0].left) + Number(list.children[0].right) }}
                                                    </div>
                                                </div>
                                            </template>
                                            <a v-else-if="type == 0 && !list.children[0]" href="javascript:;"
                                                class="register fs14 colf" @click="goregister(list, 1)">REGISTER</a>
                                            <div v-else class="fs12 col89 vacancy">VACANCY</div>

                                        </div>
                                        <div class="map_item">
                                            <template v-if="list.children && list.children[1]">
                                                <div class="top" :class="'backcolor' + list.children[1].level" @click="getlist2(list.children[1].id)">
                                                    <div class="colf">【{{ list.children[1].id }}】</div>
                                                    <div class="colf opa">{{ list.children[1].idnum }}</div>
                                                </div>
                                                <div class="flex-box bottom" @click="getlist2(list.children[1].id)">
                                                    <div class="flex-grow-2">总</div>
                                                    <div class="flex-grow-5">
                                                        {{ Number(list.children[1].left) + Number(list.children[1].right) }}
                                                    </div>
                                                </div>
                                            </template>
                                            <a v-else-if="type == 0 && list" href="javascript:;" class="register fs14 colf"
                                                @click="goregister(list, 2)">REGISTER</a>
                                            <div v-else class="fs12 col89 vacancy">VACANCY</div>

                                        </div>
                                    </div>
                                    <div class="flex-box flex-around">
                                        <div class="line2"></div>
                                        <div class="line2"></div>
                                    </div>
                                    <div class="map_line map_line3 flex-box flex-around">
                                        <div class="map_item">
                                            <template v-if="list.children && list.children[0] && list.children[0].children[0]">

                                                <div class="top"
                                                    :class="'backcolor' + list.children[0].children[0].level" @click="getlist2(list.children[0].children[0].id)">
                                                    <div class="colf">【{{ list.children[0].children[0].id }}】
                                                    </div>
                                                    <div class="colf opa">{{ list.children[0].children[0].idnum }}
                                                    </div>
                                                </div>
                                                <div class="flex-box bottom" @click="getlist2(list.children[0].children[0].id)">
                                                    <div class="flex-grow-2">总</div>
                                                    <div class="flex-grow-5">
                                                        {{ Number(list.children[0].children[0].left) + Number(list.children[0].children[0].right) }}
                                                    </div>
                                                </div>
                                            </template>
                                            <a v-else-if="type == 0 && list.children && list.children[0] && list.children[0].children && !list.children[0].children[0]"
                                                href="javascript:;" class="register fs14 colf"
                                                @click="goregister(list.children[0], 1)">REGISTER</a>
                                            <!-- <div v-else-if="(!list.children||!list.children[0]||!list.children[0].children)" class="fs12 col89 vacancy">VACANCY</div> -->
                                            <div v-else class="fs12 col89 vacancy">VACANCY</div>

                                        </div>
                                        <div class="map_item">
                                            <template v-if="list.children && list.children[0] && list.children[0].children[1]">

                                                <div class="top"
                                                    :class="'backcolor' + list.children[0].children[1].level"  @click="getlist2(list.children[0].children[1].id)">
                                                    <div class="colf">【{{ list.children[0].children[1].id }}】
                                                    </div>
                                                    <div class="colf opa">{{ list.children[0].children[1].idnum }}
                                                    </div>
                                                </div>
                                                <div class="flex-box bottom" @click="getlist2(list.children[0].children[1].id)">
                                                    <div class="flex-grow-2">总</div>
                                                    <div class="flex-grow-5">
                                                        {{ Number(list.children[0].children[1].left) + Number(list.children[0].children[1].right) }}
                                                    </div>
                                                </div>
                                            </template>
                                            <a v-else-if="type == 0 && list.children && list.children[0] && list.children[0].children && !list.children[0].children[1]"
                                                href="javascript:;" class="register fs14 colf"
                                                @click="goregister(list.children[0], 2)">REGISTER</a>
                                            <!-- <div v-else-if="(!list.children||!list.children[0]||!list.children[0].children)" class="fs12 col89 vacancy">VACANCY</div> -->
                                            <div v-else class="fs12 col89 vacancy">VACANCY</div>

                                        </div>
                                        <div class="map_item">
                                            <template v-if="list.children && list.children[1] && list.children[1].children[0]">
                                                <div class="top"
                                                    :class="'backcolor' + list.children[1].children[0].level" @click="getlist2(list.children[1].children[0].id)">
                                                    <div class="colf">【{{ list.children[1].children[0].id }}】
                                                    </div>
                                                    <div class="colf opa">{{ list.children[1].children[0].idnum }}
                                                    </div>
                                                </div>
                                                <div class="flex-box bottom" @click="getlist2(list.children[1].children[0].id)">
                                                    <div class="flex-grow-2">总</div>
                                                    <div class="flex-grow-5">
                                                        {{ Number(list.children[1].children[0].left) + Number(list.children[1].children[0].right) }}
                                                    </div>
                                                </div>
                                            </template>
                                            <a v-else-if="type == 0 && list.children && list.children[1] && list.children[1].children && !list.children[1].children[0]"
                                                href="javascript:;" class="register fs14 colf"
                                                @click="goregister(list.children[1], 1)">REGISTER</a>
                                            <!-- <div v-else-if="(!list.children||!list.children[1]||!list.children[1].children)" class="fs12 col89 vacancy">VACANCY</div> -->
                                            <div v-else class="fs12 col89 vacancy">VACANCY</div>

                                        </div>
                                        <div class="map_item">
                                            <template v-if="list.children && list.children[1] && list.children[1].children[1]">
                                                <div class="top"
                                                    :class="'backcolor' + list.children[1].children[1].level"  @click="getlist2(list.children[1].children[1].id)">
                                                    <div class="colf">【{{ list.children[1].children[1].id }}】
                                                    </div>
                                                    <div class="colf opa">{{ list.children[1].children[1].idnum }}
                                                    </div>
                                                </div>
                                                <div class="flex-box bottom" @click="getlist2(list.children[1].children[1].id)">
                                                    <div class="flex-grow-2">总</div>
                                                    <div class="flex-grow-5">
                                                        {{ Number(list.children[1].children[1].left) + Number(list.children[1].children[1].right) }}
                                                    </div>
                                                </div>
                                            </template>
                                            <a v-else-if="type == 0 && list.children && list.children[1] && list.children[1].children && !list.children[1].children[1]"
                                                href="javascript:;" class="register fs14 colf"
                                                @click="goregister(list.children[1], 2)">REGISTER</a>
                                            <!-- <div v-else-if="(!list.children||!list.children[1]||!list.children[1].children)" class="fs12 col89 vacancy">VACANCY</div> -->
                                            <div v-else class="fs12 col89 vacancy">VACANCY</div>

                                        </div>
                                    </div>
                                    <div class="flex-box flex-around">
                                        <div class="line3"></div>
                                        <div class="line3"></div>
                                        <div class="line3"></div>
                                        <div class="line3"></div>
                                    </div>
                                    <div class="map_line map_line4 flex-box flex-around">
                                        <div class="map_item">
                                            <template
                                                v-if="list.children && list.children[0] && list.children[0].children && list.children[0].children[0] && list.children[0].children[0].children && list.children[0].children[0].children[0]">
                                                <div class="top"
                                                    :class="'backcolor' + list.children[0].children[0].children[0].level"  @click="getlist2(list.children[0].children[0].children[0].id)">

                                                    <div class="colf">
                                                        【{{ list.children[0].children[0].children[0].id }}】</div>
                                                    <div class="colf opa">
                                                        {{ list.children[0].children[0].children[0].idnum }}</div>
                                                </div>
                                                <div class="flex-box bottom"  @click="getlist2(list.children[0].children[0].children[0].id)">
                                                    <div class="flex-grow-2">总</div>
                                                    <div class="flex-grow-5">
                                                        {{ Number(list.children[0].children[0].children[0].left) + Number(list.children[0].children[0].children[0].right) }}
                                                    </div>
                                                </div>
                                            </template>
                                            <a v-else-if="type == 0 && list.children && list.children[0] && list.children[0].children && list.children[0].children[0] && list.children[0].children[0].children && !list.children[0].children[0].children[0]"
                                                href="javascript:;" class="register fs14 colf"
                                                @click="goregister(list.children[0].children[0], 1)">REGISTER</a>
                                            <!-- <div v-else-if="(!list.children||!list.children[0]||!list.children[0].children||!list.children[0].children[0]||!list.children[0].children[0].children)" class="fs12 col89 vacancy">VACANCY</div> -->
                                            <div v-else class="fs12 col89 vacancy">VACANCY</div>

                                        </div>
                                        <div class="map_item">
                                            <template
                                                v-if="list.children && list.children[0] && list.children[0].children && list.children[0].children[0] && list.children[0].children[0].children && list.children[0].children[0].children[1]">
                                                <div class="top"
                                                    :class="'backcolor' + list.children[0].children[0].children[1].level" @click="getlist2(list.children[0].children[0].children[1].id)">
                                                    <div class="colf">
                                                        【{{ list.children[0].children[0].children[1].id }}】</div>
                                                    <div class="colf opa">
                                                        {{ list.children[0].children[0].children[1].idnum }}</div>
                                                </div>
                                                <div class="flex-box bottom" @click="getlist2(list.children[0].children[0].children[1].id)">
                                                    <div class="flex-grow-2">总</div>
                                                    <div class="flex-grow-5">
                                                        {{ Number(list.children[0].children[0].children[1].left) + Number(list.children[0].children[0].children[1].right) }}
                                                    </div>
                                                </div>
                                            </template>
                                            <a v-else-if="type == 0 && list.children && list.children[0] && list.children[0].children && list.children[0].children[0] && list.children[0].children[0].children && !list.children[0].children[0].children[1]"
                                                href="javascript:;" class="register fs14 colf"
                                                @click="goregister(list.children[0].children[0], 2)">REGISTER</a>
                                            <!-- <div v-else-if="(!list.children||!list.children[0]||!list.children[0].children||!list.children[0].children[0]||!list.children[0].children[0].children)" class="fs12 col89 vacancy">VACANCY</div> -->
                                            <div v-else class="fs12 col89 vacancy">VACANCY</div>

                                        </div>
                                        <div class="map_item">
                                            <template
                                                v-if="list.children && list.children[0] && list.children[0].children && list.children[0].children[1] && list.children[0].children[1].children && list.children[0].children[1].children[0]">
                                                <div class="top"
                                                    :class="'backcolor' + list.children[0].children[1].children[0].level" @click="getlist2(list.children[0].children[1].children[0].id)">
                                                    <div class="colf">
                                                        【{{ list.children[0].children[1].children[0].id }}】</div>
                                                    <div class="colf opa">
                                                        {{ list.children[0].children[1].children[0].idnum }}</div>
                                                </div>
                                                <div class="flex-box bottom"  @click="getlist2(list.children[0].children[1].children[0].id)">
                                                    <div class="flex-grow-2">总</div>
                                                    <div class="flex-grow-5">
                                                        {{ Number(list.children[0].children[1].children[0].left) + Number(list.children[0].children[1].children[0].right) }}
                                                    </div>
                                                </div>
                                            </template>
                                            <a v-else-if="type == 0 && list.children && list.children[0] && list.children[0].children && list.children[0].children[1] && list.children[0].children[1].children && !list.children[0].children[1].children[0]"
                                                href="javascript:;" class="register fs14 colf"
                                                @click="goregister(list.children[0].children[1], 1)">REGISTER</a>
                                            <!-- <div v-else-if="(!list.children||!list.children[0]||!list.children[0].children||!list.children[0].children[1]||!list.children[0].children[1].children)" class="fs12 col89 vacancy">VACANCY</div> -->
                                            <div v-else class="fs12 col89 vacancy">VACANCY</div>

                                        </div>
                                        <div class="map_item">
                                            <template
                                                v-if="list.children && list.children[0] && list.children[0].children && list.children[0].children[1] && list.children[0].children[1].children && list.children[0].children[1].children[1]">
                                                <div class="top"
                                                    :class="'backcolor' + list.children[0].children[1].children[1].level" @click="getlist2(list.children[0].children[1].children[1].id)">
                                                    <div class="colf">
                                                        【{{ list.children[0].children[1].children[1].id }}】</div>
                                                    <div class="colf opa">
                                                        {{ list.children[0].children[1].children[1].idnum }}</div>
                                                </div>
                                                <div class="flex-box bottom" @click="getlist2(list.children[0].children[1].children[1].id)">
                                                    <div class="flex-grow-2">总</div>
                                                    <div class="flex-grow-5">
                                                        {{ Number(list.children[0].children[1].children[1].left) + Number(list.children[0].children[1].children[1].right) }}
                                                    </div>
                                                </div>
                                            </template>
                                            <a v-else-if="type == 0 && list.children && list.children[0] && list.children[0].children && list.children[0].children[1] && list.children[0].children[1].children && !list.children[0].children[1].children[1]"
                                                href="javascript:;" class="register fs14 colf"
                                                @click="goregister(list.children[0].children[1], 2)">REGISTER</a>
                                            <!-- <div v-else-if="(!list.children||!list.children[0]||!list.children[0].children||!list.children[0].children[1]||!list.children[0].children[1].children)" class="fs12 col89 vacancy">VACANCY</div> -->
                                            <div v-else class="fs12 col89 vacancy">VACANCY</div>

                                        </div>
                                        <div class="map_item">
                                            <template
                                                v-if="list.children && list.children[1] && list.children[1].children && list.children[1].children[0] && list.children[1].children[0].children && list.children[1].children[0].children[0]">
                                                <div class="top"
                                                    :class="'backcolor' + list.children[1].children[0].children[0].level" @click="getlist2(list.children[1].children[0].children[0].id)">
                                                    <div class="colf">
                                                        【{{ list.children[1].children[0].children[0].id }}】</div>
                                                    <div class="colf opa">
                                                        {{ list.children[1].children[0].children[0].idnum }}</div>
                                                </div>
                                                <div class="flex-box bottom" @click="getlist2(list.children[1].children[0].children[0].id)">
                                                    <div class="flex-grow-2">总</div>
                                                    <div class="flex-grow-5">
                                                        {{ Number(list.children[1].children[0].children[0].left) + Number(list.children[1].children[0].children[0].right) }}
                                                    </div>
                                                </div>
                                            </template>
                                            <a v-else-if="type == 0 && list.children && list.children[1] && list.children[1].children && list.children[1].children[0] && list.children[1].children[0].children && !list.children[1].children[0].children[0]"
                                                href="javascript:;" class="register fs14 colf"
                                                @click="goregister(list.children[1].children[0], 1)">REGISTER</a>
                                            <!-- <div v-else-if="(!list.children||!list.children[1]||!list.children[1].children||!list.children[1].children[0]||!list.children[1].children[0].children)" class="fs12 col89 vacancy">VACANCY</div> -->
                                            <div v-else class="fs12 col89 vacancy">VACANCY</div>


                                        </div>
                                        <div class="map_item">
                                            <template
                                                v-if="list.children && list.children[1] && list.children[1].children && list.children[1].children[0] && list.children[1].children[0].children && list.children[1].children[0].children[1]">
                                                <div class="top"
                                                    :class="'backcolor' + list.children[1].children[0].children[1].level" @click="getlist2(list.children[1].children[0].children[1].id)">
                                                    <div class="colf">
                                                        【{{ list.children[1].children[0].children[1].id }}】</div>
                                                    <div class="colf opa">
                                                        {{ list.children[1].children[0].children[1].idnum }}</div>
                                                </div>
                                                <div class="flex-box bottom" @click="getlist2(list.children[1].children[0].children[1].id)">
                                                    <div class="flex-grow-2">总</div>
                                                    <div class="flex-grow-5">
                                                        {{ Number(list.children[1].children[0].children[1].left) + Number(list.children[1].children[0].children[1].right) }}
                                                    </div>
                                                </div>
                                            </template>
                                            <a v-else-if="type == 0 && list.children && list.children[1] && list.children[1].children && list.children[1].children[0] && list.children[1].children[0].children && !list.children[1].children[0].children[1]"
                                                href="javascript:;" class="register fs14 colf"
                                                @click="goregister(list.children[1].children[0], 2)">REGISTER</a>
                                            <!-- <div v-else-if="(!list.children||!list.children[1]||!list.children[1].children||!list.children[1].children[0]||!list.children[1].children[0].children)" class="fs12 col89 vacancy">VACANCY</div> -->
                                            <div v-else class="fs12 col89 vacancy">VACANCY</div>


                                        </div>
                                        <div class="map_item">
                                            <template
                                                v-if="list.children && list.children[1] && list.children[1].children && list.children[1].children[1] && list.children[1].children[1].children && list.children[1].children[1].children[0]">
                                                <div class="top"
                                                    :class="'backcolor' + list.children[1].children[1].children[0].level" @click="getlist2(list.children[1].children[1].children[0].id)">
                                                    <div class="colf">
                                                        【{{ list.children[1].children[1].children[0].id }}】</div>
                                                    <div class="colf opa">
                                                        {{ list.children[1].children[1].children[0].idnum }}</div>
                                                </div>
                                                <div class="flex-box bottom" @click="getlist2(list.children[1].children[1].children[0].id)">
                                                    <div class="flex-grow-2">总</div>
                                                    <div class="flex-grow-5">
                                                        {{ Number(list.children[1].children[1].children[0].left) + Number(list.children[1].children[1].children[0].right) }}
                                                    </div>
                                                </div>
                                            </template>
                                            <a v-else-if="type == 0 && list.children && list.children[1] && list.children[1].children && list.children[1].children[1] && list.children[1].children[1].children && !list.children[1].children[1].children[0]"
                                                href="javascript:;" class="register fs14 colf"
                                                @click="goregister(list.children[1].children[1], 1)">REGISTER</a>
                                            <!-- <div v-else-if="(!list.children||!list.children[1]||!list.children[1].children||!list.children[1].children[1]||!list.children[1].children[1].children)" class="fs12 col89 vacancy">VACANCY</div> -->
                                            <div v-else class="fs12 col89 vacancy">VACANCY</div>


                                        </div>
                                        <div class="map_item">
                                            <template
                                            v-if="list.children&&list.children[1]&&list.children[1].children&&list.children[1].children[1]&&list.children[1].children[1].children&&list.children[1].children[1].children[1]">
                                            <div class="top"
                                                :class="'backcolor'+list.children[1].children[1].children[1].level" @click="getlist2(list.children[1].children[1].children[1].id)">
                                                <div class="colf">
                                                    【{{list.children[1].children[1].children[1].id}}】</div>
                                                <div class="colf opa">
                                                    {{list.children[1].children[1].children[1].idnum}}</div>
                                            </div>
                                            <div class="flex-box bottom" @click="getlist2(list.children[1].children[1].children[1].id)">
                                                <div class="flex-grow-2">总</div>
                                                <div class="flex-grow-5">
                                                    {{Number(list.children[1].children[1].children[1].left)+Number(list.children[1].children[1].children[1].right)}}
                                                </div>
                                            </div>
                                        </template>
                                        <a v-else-if="type==0&&list.children&&list.children[1]&&list.children[1].children&&list.children[1].children[1]&&list.children[1].children[1].children&&!list.children[1].children[1].children[1]"
                                            href="javascript:;" class="register fs14 colf"
                                            @click="goregister(list.children[1].children[1],2)">REGISTER</a>
                                        <!-- <div v-else-if="(!list.children||!list.children[1]||!list.children[1].children||!list.children[1].children[1]||!list.children[1].children[1].children)" class="fs12 col89 vacancy">VACANCY</div> -->
                                        <div v-else class="fs12 col89 vacancy">VACANCY</div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Footer></Footer>
</div></template>
<style>.backcolor1 {
    background-color: #f4dc2a !important;
}

.backcolor4 {
    background-color: #389D0F !important;
}

.backcolor3 {
    background-color: #3DAAFF !important;
}

.backcolor2 {
    background-color: #FFA29E !important;
}

.backcolor5 {
    background-color: #E20200 !important;
}</style>
<script>
import cookie from "vue-cookies";

export default {
    data() {
        return {
            amount: '',
            list: [],
            type: 1,
            choosetype:0,
            pagetype:0
        };
    },
    created() {
        if (!cookie.get('sma_token')) {
            this.$router.push({ path: '/login', query: { tabindex: 1 } })
            return
        }
        if (this.$route.query.type) {
            this.type = this.$route.query.type
            this.choosetype = this.$route.query.choosetype

        }
        if (this.$route.query.pagetype) {
            this.pagetype = this.$route.query.pagetype

        }
        console.log(this.type)
        this.getlist()
        // cookie.remove('select');

    },
    methods: {
        getlist() {

            this.$api.apiGrouplist({ idnum: this.amount }).then(ret => {
                console.log(ret)
                if (ret.code == 1) {
                    this.list = ret.data
                    console.log(this.list.children)
                } else {
                    // this.$message.error(ret.msg)
                    this.$message({
						message: ret.msg,
						type: 'error',
						offset: window.screen.height / 2
					})
                }
            })
        },
        getlist2(idnum) {

            this.$api.apiGrouplist({ idnum: idnum }).then(ret => {
                console.log(ret)
                if (ret.code == 1) {
                    this.list = ret.data
                    console.log(this.list.children)
                } else {
                    // this.$message.error(ret.msg)
                    this.$message({
						message: ret.msg,
						type: 'error',
						offset: window.screen.height / 2
					})
                }
            })
        },
        getlist3(idnum) {
            if(idnum){
                this.$api.apiGrouplist({ idnum: idnum }).then(ret => {
                console.log(ret)
                if (ret.code == 1) {
                    this.list = ret.data
                    console.log(this.list.children)
                } else {
                    // this.$message.error(ret.msg)
                    this.$message({
						message: ret.msg,
						type: 'error',
						offset: window.screen.height / 2
					})
                }
            })
            }
           
        },
        goregister(item, index) {
            console.log(item)
            var data = { item: { idnum: item.idnum, id: item.id, }, index: index }
            console.log(data)
            // return
            // cookie.set("select",'');
            if(this.pagetype==0){
                cookie.set("select", data);

                this.$router.push({ path: '/member_register' ,query:{choosetype:this.choosetype}})

            }else{
                cookie.set("select2", data);

                this.$router.push({ path: '/invitecode' ,query:{choosetype:this.choosetype}})

            }


        }
    },
};
</script>
